import React from 'react';
import * as styles from './Logos.module.scss';
import clsx from 'clsx';

const Logos = ({ logos, className}) => {
  return (
    <section className={clsx(className && className, styles.logos)}>
      <div className={clsx('section-wrapper', styles.wrapper)}>
        <div className={styles.list}>
          {logos && logos.map((logo,i) =>
            <a key={i} className={styles.cta} href={logo.investor_link.url} target={logo.investor_link.target}>
              <img className={styles.logo} src={logo.investor_logo.url} alt={logo.investor_logo.alt}/>
            </a>
          )}
        </div>
      </div>
    </section>
  )
};

export default Logos;