
import React from 'react';
import { graphql } from 'gatsby';
import clsx from 'clsx';
import Layout from '../components/layout';
import SearchEngineOpt from '../components/SearchEngineOpt/SearchEngineOpt';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Callout from '../components/Callout/Callout';
import Cards from '../components/Cards/Cards';
import Hero from '../components/Hero/Hero';
import ImageText from '../components/ImageText/ImageText';
import Logos from '../components/Logos/Logos';
import TextSection from '../components/TextSection/TextSection';

export default function InvestorsPage({data}) {
  return (
    <Layout>
      <div className="page">
        <Hero className={clsx(data.page.data.header_css_class.text, 'hero-investors')} text={data.page.data.header_text.html} image={data.page.data.header_image} />
        <Breadcrumb className={data.page.data.breadcrumb_css_class && data.page.data.breadcrumb_css_class.text} list={data.page.data.breadcrumb}/>
        {data.page.data.sections.map((section, index) => (
          <ImageText key={index} data={section} />
        ))}

        {
          data.page.data.press_status &&
          <Cards
            headline={data.page.data.press_headline.html}
            cards={data.page.data.press}
            className='bg-gray-light sub-headlined'
          />
        }

        {
          data.page.data.events_status &&
          <Cards
            headline={data.page.data.events_headline.html}
            cards={data.page.data.events}
            className='bg-gray-light sub-headlined'
          />
        }
        {
          data.page.data.thought_status &&
          <Cards
            headline={data.page.data.thought_leadership_headline.html}
            cards={data.page.data.thought_leadership}
            className='bg-gray-light sub-headlined'
          />
        }

        <TextSection text={data.page.data.investors_headline && [data.page.data.investors_headline.html]} className="black-text bg-gray-light"/>
        <Logos logos={data.page.data.investors}/>

        <Callout
          text={ data.page.data.callout_text.html }
          cta={{
            text: data.page.data.callout_cta_text.text,
            url: data.page.data.callout_cta_url
          }}
          image={ data.page.data.callout_image }
        />
      </div>
    </Layout>
  )}

  export const Head = ({ data }) => (
    <SearchEngineOpt
      title={data.page.seo.title && data.page.seo.title.text}
      description={data.page.seo.description && data.page.seo.description.text}
      image={data.page.seo.image && data.page.seo.image.url}
      keywords={''} />
  )

export const InvestorsQuery = graphql`
query {
  page: prismicInvestors {
    uid
    seo: data {
      title {
        text
      }
      description {
        text
      }
      image {
        url
      }
    }
    data {
      header_text {
        html
      }
      header_image {
        url
        alt
      }
      header_css_class {
        text
      }      
      breadcrumb {
        link_text {
          text
        }
        link_url {
          target
          slug
          link_type
          url
        }
      }
      breadcrumb_css_class {
        text
      }
			sections {
        headline {
          html
        }
        paragraph {
          html
        }
        image {
          url
          alt
        }
        css_class {
          text
        }
        image_css_class {
          text
        }
        background_image {
          url
        }
        component_type {
          text
        }
      } 
      investors_headline {
        html
      }
      investors {
        investor_logo {
          url
          alt
        }
        investor_link {
          url
          target
        }
      }
      press_status
      press_headline {
        html
      }
      press {
        date {
          text
        }
        title {
          text
        }
        link {
          url
          target
        }
      }
      events_status
      events_headline {
        html
      }
      events {
        date {
          text
        }
        title {
          text
        }
        link {
          url
          target
        }
      }
      thought_status
      thought_leadership_headline {
        html
      }
      thought_leadership {
        date {
          text
        }
        title {
          text
        }
        link {
          url
          target
        }
      }      
   
      callout_text {
        html
      }
      callout_image {
        url
        alt
      }
      callout_cta_text {
        text
      }
      callout_cta_url {
        target
        slug
        link_type
        url
      }
		
    }
  }
}
`
